import { makeAutoObservable } from 'mobx';
import { render } from 'react-dom';

export default class {
  activeMapView = 'terrain';
  bounds = [[], []];
  countryBackgroundImageUrls = [];
  displayMap = false;
  focusedProjectId = null;
  innerSwiperSlideIndex = 0;
  layerAndSourceIds = [];
  loading = true;
  map;
  mapDefaultZoomLevel = 13;
  outerSwiperInitialized = false;
  outerSwiperSlideIndex = 0;
  pichaPhotosUrl;
  projectsList = new Map();
  photoUrlsLoaded = false;
  satelliteView = 'ckcmah0ll1g611hpjkm391b6k';
  selectedCountryCode = '';
  terrainView = 'ckcm3qylt19hg1js25ezenz75';
  tooltipPopup;
  userId = '';
  userName = '';
  userProjectSummaryText = '';

  constructor() {
    makeAutoObservable(this);
  }

  get mapStyle() {
    if (this.activeMapView == 'terrain') {
      return `mapbox://styles/charitywater/${this.terrainView}`;
    } else {
      return `mapbox://styles/charitywater/${this.satelliteView}`;
    }
  }

  setMapStyle = () => {
    this.map.setStyle(this.mapStyle);
  };

  sourceUrlFor = (path) => `/api/v1/projects${path}?user_id=${this.userId}`;

  fitMapToResultBounds = (bounds) => {
    this.map.fitBounds(bounds, {
      padding: {
        bottom: 120,
        left: 120,
        right: 120,
        top: 120,
      },
      linear: true,
      maxZoom: 11,
    });
  };

  removeExistingTooltip = () => {
    if (this.tooltipPopup) {
      this.tooltipPopup.remove();
    }
  };

  fitAndZoomMap = () => {
    this.removeExistingTooltip();

    if (this.bounds) {
      this.fitMapToResultBounds(this.bounds);
    } else {
      this.map.zoomTo(2);
    }
  };

  findProject = (id) => {
    let project = null;
    /* eslint-disable no-unused-vars */
    for (const [country, projects] of this.projectsList) {
      project = projects.find((project) => project.id == id);
      if (project) {
        break;
      }
    }
    /* eslint-enable no-unused-vars */
    return project;
  };

  showProject = (id, event) => {
    const project = this.findProject(id);
    const coordinates = [project.longitude, project.latitude];
    const tooltip = <div className='font-sans'>{project.name}</div>;

    if (event) {
      this.map.easeTo({
        center: event.features[0].geometry.coordinates,
        speed: 0.4,
        curve: 1,
        duration: 1500,
      });
      while (Math.abs(event.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += event.lngLat.lng > coordinates[0] ? 360 : -360;
      }
    }

    this.removeExistingTooltip();
    this.tooltipPopup = new window.mapboxgl.Popup({
      className: `project-tooltip ${project.project_category}-funding-source`,
      closeButton: false,
    })
      .setLngLat(coordinates)
      .setHTML('')
      .addTo(this.map);
    const tooltipContent = document.querySelector(
      '.project-tooltip .mapboxgl-popup-content'
    );
    if (tooltipContent) {
      render(tooltip, tooltipContent);
    }
    this.tooltipPopup.on('close', () => {
      this.focusedProjectId = null;
    });

    this.selectedCountryCode = project.country_code;
    this.innerSwiperSlideIndex = Array.from(
      this.projectsList.get(this.selectedCountryCode).values()
    ).findIndex((value) => value.id == project.id);
    this.focusedProjectId = project.id;
  };

  addProjectsToMap = () => {
    this.map.addSource('projects', {
      data: this.sourceUrlFor('/'),
      type: 'geojson',
    });

    this.map.addLayer({
      id: 'projects',
      type: 'circle',
      source: 'projects',
      paint: {
        'circle-color': '#f8ca46',
        'circle-radius': {
          stops: [
            [2, 3],
            [8, 5],
          ],
        },
        'circle-stroke-color': '#fff',
        'circle-stroke-width': 1,
      },
    });

    this.bindPointsMouseEventsFor('projects');
    this.layerAndSourceIds.push('projects');
  };

  setMapView = (viewId, themeType) => {
    this.activeMapView = viewId;

    if (viewId == 'satellite') {
      this.map.setMaxZoom(11);
    } else {
      this.map.setMaxZoom(this.mapDefaultZoomLevel);
    }

    this.map.setStyle(`mapbox://styles/charitywater/${themeType}`);
  };

  setToggleButtonClass = (view) =>
    `no-underline cursor-pointer ${view == this.activeMapView ? 'active' : ''}`;

  bindPointsMouseEventsFor = (id) => {
    this.map.on('click', id, (e) => {
      this.showProject(e.features[0].properties.id, e);
    });

    this.map.on('mouseenter', id, () => {
      this.map.getCanvas().style.cursor = 'pointer';
    });

    this.map.on('mouseleave', id, () => {
      this.map.getCanvas().style.cursor = '';
    });
  };

  setVisibleLayers = () => {
    this.layerAndSourceIds.forEach((layerId) => {
      this.map.setLayoutProperty(layerId, 'visibility', 'visible');
    });
  };

  countryNameFromCode = (countryCode) =>
    Array.from(this.projectsList.get(countryCode).values())[0].country_name;
}
