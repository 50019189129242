import numeral from 'numeral';

export function amountWithComma(amount) {
  return numeral(amount).format('0,0');
}

export function amountWithPrecisionAsRequired(amount) {
  return numeral(amount).format('0[.]00');
}

export function amountWithPrecisionAsRequiredAndComma(amount) {
  return numeral(amount).format('0,0[.]00');
}

export function amountWithAbbreviation(amount) {
  return numeral(amount).format('0[.]0a');
}
